import VueRouter from 'vue-router';

//定义一个路由转发
const routes = [
    {
        path:'/',
        name:'login',
        component:()=>import('../components/Login')
    },
    {
        path: '/Index',
        name: 'index',
        component:()=>import('../components/Index'),
    //    子路由
        children:[
            {
            path: '/Home',
            name: 'home',
            meta:{
              title:'首页'
            },
            component:()=>import('../components/Home')
           },
           {
            path: '/UserHome',
            name: 'userhome',
            meta:{
              title:'个人中心'
            },
            component:()=>import('../components/UserHome')
           },
        ]
    }
]

const router = new VueRouter({
    mode:'history',
    routes
})


//路由守卫
// 转发路由之前
router.beforeEach((to, from, next) => {
    if (to.path==='/') {
        next();
    }
    const CurUser = localStorage.getItem('CurUser');
    if(!CurUser && to.path !== '/'){
        return next("/");
    }
    next();
})

export function resetRouter() {
    router.matcher = new VueRouter({
        mode:'history',
        routes: []
    }).matcher
}


//解决路由报错问题
const VueRouterPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(to) {
    return VueRouterPush.call(this,to).catch(err => err)
}

export default router;