<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>

export default {
  name: 'App',
  components: {},
  data() {
    return {
      user: JSON.parse(localStorage.getItem('CurUser')),
    }
  },
  // 监听属性
  watch: {
    '$store.state.menu': {
      handler(val, old) {
        if (!old && this.user && this.user.id) {
          this.$store.commit("setMenu", val)
        }
      },
      // 立即执行
      immediate: true
    }
  }
}
</script>

<style>
#app {
  height: 100%;
}
</style>
