import Vue from 'vue'
import App from './App.vue'
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
import './assets/global.css'
import axios from "axios";
import VueRouter from "vue-router";
import router from "./router/index";
import store from "./store";

//设置baseURL，就不需要打前缀了
// axios.defaults.baseURL="http://localhost:8090/wms"
//表示我们可以采用$axios代替axios
// Vue.prototype.$axios=axios;

Vue.config.productionTip = false

Vue.use(VueRouter)
Vue.use(ElementUI,{size:'small'});
//让.vue结尾的文件能够访问
new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
